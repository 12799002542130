import React, { useEffect, useState } from 'react'
import SlickSlider from 'react-slick'
import Trans from './atomic/Trans'
import { api } from 'utils'
import { UTENTE } from 'utils/endpoint'
import { renderCardWithProps } from 'react-components/organisms/AreaPersonaleRaccoltaContenutiEditForm/AreaPersonaleRaccoltaContenutiEditForm'

const NewsHome2021 = ({ menu }) => {
  const [items, setItems] = useState(
    menu?.children?.filter(item => item.linked_object).map(item => item.linked_object)
  )

  if (!items.length) return null

  const sliderSettings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  function checkTools() {
    const params = items.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    api.get(UTENTE + 'tools/', { params }).then(response => {
      const tmp = items.map(item => {
        const current = response.data.find(
          e => e.content_type_id === item.model && e.object_id === item.id
        )

        const { saved, following, file_url, pdf_file } = current

        return { extra: { ...item.extra, file_url, pdf_file }, saved, following, ...item }
      })

      setItems(tmp)
    })
  }

  useEffect(() => {
    checkTools()
  }, [])

  return (
    <section className="news-home2021">
      <div className="w-container-small">
        <p className="news-home2021__intro">
          {menu?.name ? menu.name : <Trans chiave="News e aggiornamenti" />}
        </p>
        <h3 className="news-home2021__title">
          {menu?.description ? menu.description : <Trans chiave="Le ultime notizie per te" />}
        </h3>

        {/* Versione desktop */}
        <div className="news-home2021__list">
          <div className="news-home2021__list__item">
            <div className="news-home2021__item">
              {renderCardWithProps(items[0], { size: 'small' })}
            </div>
            <div className="news-home2021__item">
              {renderCardWithProps(items[1], { size: 'small' })}
            </div>
          </div>
          <div className="news-home2021__list__item">
            <div className="news-home2021__item">
              {renderCardWithProps(items[2], { variant: 'horizontal-noimage', horizontal: true })}
            </div>
            <div className="news-home2021__item">
              {renderCardWithProps(items[3], { variant: 'horizontal-noimage', horizontal: true })}
            </div>
          </div>
        </div>

        {/* Versione tablet + mobile */}
        <div className="news-home2021__slider">
          <SlickSlider {...sliderSettings}>
            {items.map((item, index) => (
              <div className="news-home2021__slide" key={index}>
                {renderCardWithProps(item, {})}
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  )
}

export default NewsHome2021
