import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import SlickSlider from 'react-slick'
import { ChevronLeft, ChevronRight } from 'components/icons'
import ArticoloCard from 'components/card/ArticoloCard'
import StrumentoCard from 'components/card/StrumentoCard'
import RaccoltaCard from 'components/card/RaccoltaCard'
import LezioneCard from 'components/card/LezioneCard'
import { api, LEZIONE } from 'utils'
import { UTENTE } from 'utils/endpoint'
import { sendGTMData } from 'utils/gtm.js'
import Trans from 'components/atomic/Trans'

const TemiHome2021 = ({ menu }) => {
  let prev = useRef(null)
  let next = useRef(null)
  let slider = useRef(null)

  const [items, setItems] = useState(menu?.children?.slice(0, 3).map((item) => item.linked_object))

  const sliderSettings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handlePrev = () => {
    slider.slickPrev()
  }

  const handleNext = () => {
    slider.slickNext()
  }

  function checkTools() {
    const params = items.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    api.get(UTENTE + 'tools/', { params }).then((response) => {
      const tmp = items.map((item) => {
        const current = response.data.find(
          (e) => e.content_type_id === item.model && e.object_id === item.id
        )

        const { saved, following, file_url, pdf_file } = current

        return { extra: { ...item.extra, file_url, pdf_file }, saved, following, ...item }
      })

      setItems(tmp)
    })
  }

  useEffect(() => {
    checkTools()
  }, [])

  const renderCard = (item) => {
    if (!(item && item?.model_class)) return null

    switch (item?.model_class) {
      case 'strumento':
        return <StrumentoCard strumento={item} size="small" />
      case 'articolo':
        return <ArticoloCard articolo={item} size="small" />
      case 'lezione':
        return <LezioneCard articolo={item} size="small" />
      default:
        return <RaccoltaCard raccolta={item} size="small" />
    }
  }

  function trackCta(value) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'tema_del_momento',
        eventLabel: value.toGTMFormat(),
      })
    }
  }

  return (
    <div className="w-container-small">
      <section className="temi-home2021">
        <div className="temi-home2021__content">
          <h3 className="temi-home2021__intro">
            <Trans chiave="TEMI E APPROFONDIMENTI" />
          </h3>
          <div className="temi-home2021__title" dangerouslySetInnerHTML={{ __html: menu.name }} />
          <div
            className="temi-home2021__description"
            dangerouslySetInnerHTML={{ __html: menu.description }}
          />
          <div className="temi-home2021__cta-box">
            <Link legacyBehavior href={menu.final_url ?? '#'}>
              <a
                className="button button--primary"
                onClick={() => trackCta(menu?.cta?.length > 0 ? menu?.cta : 'Lasciati ispirare')}
              >
                {menu?.cta?.length > 0 ? menu?.cta : 'Lasciati ispirare'}
              </a>
            </Link>
          </div>
        </div>
        <div className="temi-home2021__slider">
          <div className="temi-home2021__slider-nav">
            <button
              className={`temi-home2021__slider-nav-item temi-home2021__slider-nav-item--prev`}
              onClick={handlePrev}
              ref={(self) => (prev = self)}
            >
              <ChevronLeft />
            </button>
            <button
              className={`temi-home2021__slider-nav-item temi-home2021__slider-nav-item--next`}
              onClick={handleNext}
              ref={(self) => (next = self)}
            >
              <ChevronRight />
            </button>
          </div>

          <SlickSlider ref={(self) => (slider = self)} {...sliderSettings}>
            {items.map((item, index) => (
              <div className="temi-home2021__item" key={index}>
                {renderCard(item)}
              </div>
            ))}
          </SlickSlider>
        </div>
      </section>
    </div>
  )
}

export default TemiHome2021
