import React from 'react'
import Link from 'next/link'
import SlickSlider from 'react-slick'
import Trans from 'components/atomic/Trans'
import LazyLoad from 'react-lazyload'
import { CAS_DOMAIN } from 'utils'
import { sendGTMData } from 'utils/gtm.js'

const ProposteHome2021 = ({ menu }) => {
  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 999999,
        settings: {
          customPaging: (i) => <button>{menu?.children?.[i].name}</button>,
        },
      },
      {
        breakpoint: 767,
        settings: {
          customPaging: (i) => <button>{i + 1}</button>,
        },
      },
    ],
  }

  function trackCta(value, slide) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'proposte',
        eventLabel: value.toGTMFormat(),
        moreInfo: slide?.toGTMFormat(),
      })
    }
  }

  return (
    <section className="proposte-home2021">
      <div className="w-container-small">
        <div className="proposte-home2021__content">
          <h3 className="proposte-home2021__title">{menu?.name}</h3>
          <p className="proposte-home2021__description">{menu?.description}</p>
        </div>
        <div className="proposte-home2021__slider">
          <SlickSlider {...sliderSettings}>
            {menu?.children?.map((item) => (
              <div className="proposte-home2021__slide">
                <div
                  className="proposte-home2021__item"
                  style={{ backgroundColor: `#${item.background_color || 'FF9F00'}` }}
                >
                  <div className="proposte-home2021__item__image-box">
                    <LazyLoad height={189} offset={100} once>
                      <img
                        src={CAS_DOMAIN + item?.image}
                        alt={item?.name.toAltTitle()}
                        title={item?.name.toAltTitle()}
                        className="proposte-home2021__item__image"
                      />
                    </LazyLoad>
                  </div>
                  <div className="proposte-home2021__item__content">
                    <p
                      className="proposte-home2021__item__title"
                      dangerouslySetInnerHTML={{ __html: item?.name }}
                    />
                    <p
                      className="proposte-home2021__item__description"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                    <div className="proposte-home2021__item__cta-box">
                      <Link legacyBehavior href={item?.final_url}>
                        <a
                          className="button button--primary"
                          target={item.blank ? '_blank' : null}
                          style={{ backgroundColor: `#${item.cta_color || '0375F8'}` }}
                          onClick={() => trackCta(item.cta, item.name)}
                        >
                          <Trans chiave={item?.cta?.length > 0 ? item?.cta : 'Scopri di più'} />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  )
}

export default ProposteHome2021
