import Link from 'next/link'
import React from 'react'
import { CAS_DOMAIN } from 'utils'
import { sendGTMData } from 'utils/gtm.js'
import { Image } from 'react-components/atoms'

const ServiziHome2021 = ({ menu }) => {
  function trackCta(parent, value) {
    if (parent && value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'servizi',
        eventLabel: parent.toGTMFormat(),
        moreInfo: value.toGTMFormat(),
      })
    }
  }

  return (
    <section className="servizi-home2021">
      <div className="w-container-small">
        <h3 className="servizi-home2021__title">{menu?.name}</h3>
        <div className="servizi-home2021__list">
          {menu.children?.map((item, i) => (
            <div className="servizi-home2021__item" key={`servizi-home2021-item__${i}`}>
              <div className="servizi-home2021__item__image-box">
                <Image
                  src={
                    item?.image
                      ? CAS_DOMAIN + item?.image
                      : i % 2 === 0
                      ? '/images/home2021/docenti.png'
                      : '/images/home2021/famiglie.png'
                  }
                  className="servizi-home2021__item__image"
                  alt={menu?.name?.toAltTitle()}
                  title={menu?.name?.toAltTitle()}
                  width={370}
                  height={200}
                />
              </div>
              <h2 className="servizi-home2021__item__title">{item?.name}</h2>
              <div className="servizi-home2021__item__menu">
                {item?.children.map((subitem, key) => (
                  <Link
                    legacyBehavior
                    href={subitem?.final_url ?? '#'}
                    key={`servizi-home2021-subitem__${subitem?.id || key}`}
                  >
                    <a
                      className="button button--secondary"
                      target={subitem.blank ? '_blank' : null}
                      onClick={() => trackCta(item.name, subitem.name)}
                    >
                      {subitem?.name}
                    </a>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ServiziHome2021
