const EmbeddedVimeo = ({ id }) => {
  return (
    <iframe
      src={`https://player.vimeo.com/video/${id}`}
      height="200"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
      loading="lazy"
    />
  )
}

export default EmbeddedVimeo
