import React from 'react'
import SlickSlider from 'react-slick'
import Trans from 'components/atomic/Trans'
import { Image } from 'react-components/atoms'
import { LOGIN_URL } from 'utils'
import { sendGTMData } from 'utils/gtm.js'
import { useSelector } from 'react-redux'

const HeroHome2021 = ({ menu }) => {
  const { utente } = useSelector(state => state.utente)

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  }

  function trackCta(value) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'hero_hp',
        eventLabel: value.toGTMFormat(),
      })
    }
  }

  // rimanda gli utenti loggati alla landing io+ se il link è al login/registrazione
  function getLink(href) {
    if (!href) return ''
    else if (utente && (href.includes('/registrazione') || href.includes(LOGIN_URL)))
      return '/io-piu'
    return href
  }

  return (
    <section className="hero-home2021">
      <div className="hero-home2021__slider">
        <SlickSlider {...sliderSettings}>
          {menu?.children?.map((item, index) => (
            <div className="hero-home2021__item" key={index}>
              <div className="hero-home2021__item__image-box hero-home2021__item__image-box--desktop">
                <Image
                  src={item?.image}
                  alt={item?.name.toAltTitle()}
                  title={item?.name.toAltTitle()}
                  layout="responsive"
                  width={1920}
                  height={422}
                  quality={90}
                  priority={index === 0}
                />
              </div>
              <div className="hero-home2021__item__image-box hero-home2021__item__image-box--tablet">
                <Image
                  src={item?.tablet_image}
                  alt={item?.name.toAltTitle()}
                  title={item?.name.toAltTitle()}
                  layout="responsive"
                  width={1280}
                  height={422}
                  quality={80}
                />
              </div>
              <div className="hero-home2021__item__image-box hero-home2021__item__image-box--mobile">
                <Image
                  src={item?.mobile_image}
                  alt={item?.name.toAltTitle()}
                  title={item?.name.toAltTitle()}
                  layout="responsive"
                  width={767}
                  height={351}
                  priority={index === 0}
                />
              </div>
              <div
                className="hero-home2021__item__content"
                style={{ color: item?.text_color ? `#${item.text_color}` : undefined }}
              >
                {item?.name && (
                  <p
                    className="hero-home2021__item__title"
                    dangerouslySetInnerHTML={{ __html: item?.name }}
                  />
                )}
                {item?.description && (
                  <p
                    className="hero-home2021__item__description"
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                  />
                )}
                {item?.cta && (
                  <div className="hero-home2021__item__cta-box">
                    <a
                      className="button button--primary"
                      href={getLink(item?.final_url)}
                      target={item?.blank ? '_blank' : null}
                      onClick={() => trackCta(item.cta)}
                    >
                      <Trans chiave={item?.cta?.length > 0 ? item?.cta : 'Scopri i vantaggi'} />
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        </SlickSlider>
      </div>
    </section>
  )
}

export default HeroHome2021
