import React from 'react'
import LazyLoad from 'react-lazyload'
import { sendGTMData } from 'utils/gtm.js'
import Trans from './atomic/Trans'
import { Search } from './icons'

const RicercaHome2021 = ({ menu }) => {
  function trackRicerca(query) {
    if (query) {
      sendGTMData({
        event: 'GAevent',
        eventID: '08',
        eventCategory: 'search',
        eventAction: 'homepage',
        eventLabel: query.toGTMFormat(),
      })
    }
  }

  return (
    <section className="ricerca-home2021">
      <div className="w-container">
        <h3 className="ricerca-home2021__title ricerca-home2021__title--desktop">{menu?.name}</h3>
        <p className="ricerca-home2021__title ricerca-home2021__title--mobile">{menu?.name}</p>
        <div className="ricerca-home2021__container">
          <form
            className="ricerca-home2021__search-form"
            action="/ricerca"
            onSubmit={e => trackRicerca(e.target?.elements['q']?.value)}
          >
            <input
              type="text"
              name="q"
              className="ricerca-home2021__input"
              placeholder="Cosa stai cercando?"
            />
            <button type="submit" className="ricerca-home2021__submit">
              <Search />
            </button>
          </form>
          <div className="ricerca-home2021__tags">
            {menu?.children?.map((tag, index) => (
              <a
                href={tag?.final_url ?? '#'}
                className="ricerca-home2021__tag"
                name={tag.name}
                onClick={() => trackRicerca(tag.name)}
                key={index}
              >
                {tag.name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="ricerca-home2021__illustration">
        <LazyLoad height={200} offset={100} once>
          <img src="/images/home2021/squadra.png" />
        </LazyLoad>
      </div>
    </section>
  )
}

export default RicercaHome2021
