import React, { useState } from 'react'
import SlickSlider from 'react-slick'
import { Video } from 'components/icons'
import LazyLoad from 'react-lazyload'
import { CAS_DOMAIN } from 'utils'
import { sendGTMData } from 'utils/gtm.js'
import Icon from 'components/atomic/Icon'
import Modal from 'components/atomic/Modal'
import EmbeddedVimeo from 'components/atomic/EmbeddedVimeo'

const VideoHome2021 = ({ menu }) => {
  const [vimeoModal, setVimeoModal] = useState(false)
  const [vimeoId, setVimeoId] = useState(null)

  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  function trackCta(value) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'video',
        eventLabel: value.toGTMFormat(),
      })
    }
  }

  return (
    <section className="video-home2021">
      <div className="w-container-small">
        <h3 className="video-home2021__title">{menu.name}</h3>
        <div className="video-home2021__slider">
          <SlickSlider {...sliderSettings}>
            {menu?.children?.map(item => (
              <div className="video-home2021__slide">
                <div className="video-home2021__item">
                  {item?.linked_object?.id_vimeo ? (
                    <div
                      onClick={() => {
                        setVimeoId(item?.linked_object?.id_vimeo)
                        setVimeoModal(true)
                        trackCta(item.name)
                      }}
                    >
                      <div className="video-home2021__item__image-box">
                        <LazyLoad height={223} offset={100} once>
                          <img
                            src={CAS_DOMAIN + item?.image}
                            alt={item?.name?.toAltTitle()}
                            title={item?.name?.toAltTitle()}
                            className="video-home2021__item__image"
                          />
                        </LazyLoad>
                        <span className="video-home2021__item__player-icon">
                          <Video />
                        </span>
                      </div>
                      <p className="video-home2021__item__title">{item?.name}</p>
                      <p className="video-home2021__item__cta button button--tertiary">
                        {item?.cta?.length > 0 ? item?.cta : 'Guarda ora il video'}{' '}
                        <Icon id="arrow-right" classes="button__arrow" />
                      </p>
                    </div>
                  ) : (
                    <a
                      href={item?.final_url ?? '#'}
                      target={item.blank ? '_blank' : null}
                      onClick={() => trackCta(item.name)}
                    >
                      <div className="video-home2021__item__image-box">
                        <LazyLoad height={223} offset={100} once>
                          <img
                            src={CAS_DOMAIN + item?.image}
                            alt={item?.name?.toAltTitle()}
                            title={item?.name?.toAltTitle()}
                            className="video-home2021__item__image"
                            width={411}
                            height={223}
                          />
                        </LazyLoad>
                        <span className="video-home2021__item__player-icon">
                          <Video />
                        </span>
                      </div>
                      <p className="video-home2021__item__title">{item?.name}</p>
                      <p className="video-home2021__item__cta button button--tertiary">
                        {item?.cta?.length > 0 ? item?.cta : 'Guarda ora il video'}{' '}
                        <Icon id="arrow-right" classes="button__arrow" />
                      </p>
                    </a>
                  )}
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
      <div className="video-home2021__illustration">
        <LazyLoad height={200} offset={100} once>
          <img src="/images/home2021/forbici.png" />
        </LazyLoad>
      </div>
      {vimeoModal && vimeoId && (
        <Modal
          isOpen={vimeoModal}
          alert={false}
          close={() => setVimeoModal(false)}
          classes="modal__content--wide"
        >
          <div className="modal__content__vimeo">
            <EmbeddedVimeo id={vimeoId} />
          </div>
        </Modal>
      )}
    </section>
  )
}

export default VideoHome2021
