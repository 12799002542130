import React from 'react'
import SlickSlider from 'react-slick'
import Trans from 'components/atomic/Trans'
import Link from 'next/link'
import { CAS_DOMAIN } from 'utils'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { sendGTMData } from 'utils/gtm.js'

const SliderGreenHome2021 = ({ menu }) => {
  const { width } = useWindowDimensions()

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  function getImage(item, width) {
    return width < 768 ? (width < 576 ? item.mobile_image : item.tablet_image) : item.image
  }

  function trackCta(value, slide) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'banner_hp',
        eventLabel: value.toGTMFormat(),
        moreInfo: slide?.toGTMFormat(),
      })
    }
  }

  return (
    <section className="slider-green-home2021">
      <div className="w-container-small">
        <div className="slider-green-home2021__container">
          <SlickSlider {...sliderSettings}>
            {menu.children?.map((item, index) => (
              <div className="slider-green-home2021__slide" key={index}>
                <div
                  className="slider-green-home2021__slide__container"
                  style={{
                    backgroundImage: `url(${CAS_DOMAIN + getImage(item, width)})`,
                  }}
                >
                  <div
                    className="slider-green-home2021__slide__content"
                    style={{
                      zIndex: 99,
                      color: item?.text_color ? `#${item.text_color}` : undefined,
                    }}
                  >
                    <p
                      className="slider-green-home2021__slide__title"
                      dangerouslySetInnerHTML={{ __html: item?.name }}
                    />
                    <p
                      className="slider-green-home2021__slide__description"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                    <div
                      className={`slider-green-home2021__slide__cta-box ${
                        item?.name || item?.desciption ? '' : 'bottom'
                      }`}
                    >
                      <Link legacyBehavior href={item?.final_url ?? '#'}>
                        <a
                          className="button button--primary button--inverted"
                          target={item?.blank ? '_blank' : null}
                          onClick={() => trackCta(item.cta, item.name)}
                        >
                          <Trans chiave={item?.cta?.length > 0 ? item?.cta : 'Scopri di più'} />
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  )
}

export default SliderGreenHome2021
