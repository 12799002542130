import React from 'react'
import LazyLoad from 'react-lazyload'
import Link from 'next/link'
import Icon from 'components/atomic/Icon'
import { CAS_DOMAIN } from 'utils'
import { sendGTMData } from 'utils/gtm.js'

const IoPiuHome2021 = ({ menu }) => {
  function trackCta(value) {
    if (value) {
      sendGTMData({
        event: 'GAevent',
        eventID: '05',
        eventCategory: 'homepage',
        eventAction: 'io_piu',
        eventLabel: value.toGTMFormat(),
      })
    }
  }

  return (
    <div className="w-container">
      <section className="iopiu-home2021">
        <div className="iopiu-home2021__content">
          <span className="iopiu-home2021__icon">
            <Icon id="ioplus" />
          </span>
          <p className="iopiu-home2021__title" dangerouslySetInnerHTML={{ __html: menu?.name }} />
          <p
            className="iopiu-home2021__description"
            dangerouslySetInnerHTML={{ __html: menu?.description }}
          />
          <Link legacyBehavior href={menu?.final_url ?? '#'}>
            <a
              className="button button--primary iopiu-home2021__cta"
              target={menu?.blank ? '_blank' : null}
              onClick={() => trackCta(menu.cta)}
            >
              {menu?.cta?.length > 0 ? menu?.cta : 'Scopri i vantaggi'}
            </a>
          </Link>
        </div>
        <div className="iopiu-home2021__list">
          {menu?.children?.map((item) => (
            <div className="iopiu-home2021__item">
              <div className="iopiu-home2021__item__image-box">
                <LazyLoad width={114} height={114} offset={100} once>
                  <img
                    src={
                      item?.image ? CAS_DOMAIN + item?.image : 'https://via.placeholder.com/114x114'
                    }
                    className="iopiu-home2021__item__image"
                    alt={item?.name?.toAltTitle()}
                    title={item?.name?.toAltTitle()}
                  />
                </LazyLoad>
              </div>
              <p
                className="iopiu-home2021__item__title"
                dangerouslySetInnerHTML={{ __html: item?.name }}
              />
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default IoPiuHome2021
