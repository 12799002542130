import { DOMAIN, escape } from 'utils'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

// DOCS https://github.com/garmeeh/next-seo

const MetaSeo = ({ seo, additionalMetaTags = null, article = false, home = false }) => {
  const { title, description, og_title, og_description, og_image, noindex = false } = seo

  const router = useRouter()

  const ogImgae = og_image ? og_image : '/images/icons/logo_og.png'

  const og = {
    locale: 'it_IT',
    site_name: 'Giunti Scuola',
    url: DOMAIN + router.asPath,
    title: og_title,
    description: og_description,
    images: [{ url: DOMAIN + ogImgae }],
    type: article ? 'article' : 'website',
  }

  return (
    <NextSeo
      title={escape(title)}
      description={escape(description)}
      additionalMetaTags={additionalMetaTags}
      noindex={noindex}
      openGraph={og}
    />
  )
}

export default MetaSeo
