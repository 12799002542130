import { PAGINEDETAIL } from 'utils/endpoint'

import MetaSeo from 'components/atomic/MetaSeo'
import { api } from 'utils'
import IoPiuHome2021 from 'components/layout/menu/IoPiuHome2021'
import HeroHome2021 from 'components/layout/menu/HeroHome2021'
import ServiziHome2021 from 'components/layout/menu/ServiziHome2021'
import TemiHome2021 from 'components/layout/menu/TemiHome2021'
import SliderGreenHome2021 from 'components/layout/menu/SliderGreenHome2021'
import RicercaHome2021 from 'components/RicercaHome2021'
import NewsHome2021 from 'components/NewsHome2021'
import VideoHome2021 from 'components/layout/menu/VideoHome2021'
import ProposteHome2021 from 'components/layout/menu/ProposteHome2021'
import { useSelector } from 'react-redux'

const Home = ({ page, props }) => {
  const menu = useSelector((state) => state.menu.menu)

  const handleComponents = (child) => {
    switch (child?.key) {
      case 'home-hero':
        return <HeroHome2021 menu={child} />
      case 'home-servizi':
        return <ServiziHome2021 menu={child} />
      case 'home-temi':
        return <TemiHome2021 menu={child} />
      case 'home-slider':
        return <SliderGreenHome2021 menu={child} />
      case 'home-ricerca':
        return <RicercaHome2021 menu={child} />
      case 'home-news':
        return <NewsHome2021 menu={child} />
      case 'home-video':
        return <VideoHome2021 menu={child} />
      case 'home-proposte':
        return <ProposteHome2021 menu={child} />
      case 'home-io+':
        return <IoPiuHome2021 menu={child} />
      default:
        return null
    }
  }

  const menuHomeNew = menu?.find((item) => item?.key === 'home-new')

  return (
    <main className="home-page">
      <MetaSeo seo={page?.seo} home />
      {menuHomeNew ? menuHomeNew.children?.map((child) => handleComponents(child)) : null}
    </main>
  )
}

export const getStaticProps = async () => {
  const pageResponse = await api.get(PAGINEDETAIL, {
    params: { chiave: 'homepage' },
  })
  const page = await pageResponse.data

  const toolbarProps = { toolbarPage: page }

  return { props: { page, ...toolbarProps }, revalidate: 60 }
}

export default Home
